<template>
    <div class='widget logs-explorer'>
      <ul class="logs-explorer-list overflow-auto py-4">
        <li class="logs-explorer-item" v-for="logGroup in getLogGroups" :key="logGroup.group_name">
          <h5 class="logs-explorer-item-title"><i class='fa fa-folder' aria-hidden='true'></i> {{ logGroup.group_name }}</h5>
          <ul class="logs-explorer-file-list pl-3">
            <li class="logs-explorer-file-item" v-for="file in logGroup.files" :key="file">
              <button class="btn text-left w-100" @click="getFilename(file)"><i class='fa fa-file-text-o' aria-hidden='true'></i> {{file}}</button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import dayjs from 'dayjs';
import ru from 'dayjs/locale/ru';
import duration from 'dayjs/plugin/duration';
import { debounce } from 'debounce';
import notificationMixin from '@/mixins/notificationMixin';
import VCalendar from 'v-calendar/lib/components/date-picker.umd';
import VueMultiselect from 'vue-multiselect';
import DeleteModal from '@/components/Modal/DeleteModal.vue';
import Switcher from '@/components/Switcher.vue';
import Preloader from '@/components/Preloader.vue';
import BlogArticlesTable from '@/components/Blog/Article/BlogArticlesTable.vue';

export default {
    name: 'LogsExplorerComponent',
    components: {
    },
    data() {
        return {
          groups: []
        };
    },
    async created() {
      await this.loadLogGroups();
      this.groups = this.getLogGroups
      console.log(this.getLogGroups)
    },
    computed: {
        ...mapGetters({
          getLogGroups: "logs/getLogGroups"
        }),
    },
    methods: {
        ...mapActions({
          loadLogGroups: 'logs/loadLogGroups',
        }),
        getFilename(fileName) {
          this.$emit('selected', fileName)
        }
    },
};
</script>

<style lang='scss' scoped>
.logs-explorer {
  height: 100%;
  max-height: 900px;
  .logs-explorer-list {
    height: 100%;
  }
}
</style>