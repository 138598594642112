<template>
    <div class='logs'>
<!--        <div class='filter__container'>-->
<!--            <div class='row mb-3 justify-content-between'>-->
<!--                <div class='input-group col-12 col-xl-6'>-->
<!--                    <input type='search' class='form-control' v-model='searchWord' id='search-input'>-->
<!--                    <span class='input-group-append'>-->
<!--                        <button type='button' class='btn btn-default' @click='search'>Искать</button>-->
<!--                    </span>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
      <div class="row">
        <div class="col-2">
          <LogsExplorerComponent @selected="selectFile"></LogsExplorerComponent>
        </div>

        <div class="col-10">
          <div class='p-5 logs-file widget m-0'>
            <div class="h-100 logs-file-wrapper overflow-auto">
              <ul class="logs-file-list" >
                <li
                    class="log-file-item row mb-3"
                    v-for="log in getLogFile.logs"
                    :key="log.line"
                    @click="getStacktrace(log.stacktrace, currentFile, log.line)">
                  <div class="col-12 d-flex align-items-start position-relative">
                    <i class='fa fa-chevron-right mr-3 mt-1 stacktrace-chevron' :class="{transparent: !log.stacktrace}" aria-hidden='true'></i>
                    <p class="ml-4 mb-2">
                      <i class='fa fa-info-circle mr-1'
                         :class="{
                          warning: log.type === 'warning',
                          info: log.type === 'info',
                          error: log.type === 'error',
                      }"
                         aria-hidden='true'></i>
                      <span class="mr-2">{{log.datetime}} </span>
                      <span>{{log.message}}</span>
                    </p>
                  </div>
                  <div class="stacktrace-wrapper col-12 "
                      v-if="stackTrace.isShow && stackTrace.line === log.line"
                  >
                    <div class="stacktrace-data p-4">
                      {{ getLogLineDetail.stacktrace }}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import dayjs from 'dayjs';
import ru from 'dayjs/locale/ru';
import duration from 'dayjs/plugin/duration';
import { debounce } from 'debounce';
import notificationMixin from '@/mixins/notificationMixin';
import VCalendar from 'v-calendar/lib/components/date-picker.umd';
import VueMultiselect from 'vue-multiselect';
import DeleteModal from '@/components/Modal/DeleteModal.vue';
import Switcher from '@/components/Switcher.vue';
import Preloader from '@/components/Preloader.vue';
import BlogArticlesTable from '@/components/Blog/Article/BlogArticlesTable.vue';
import LogsExplorerComponent from "@/pages/Admin/System/Logs/LogsExplorerComponent.vue";

export default {
    name: 'LogsComponent',
    components: {
        LogsExplorerComponent,
    },
    data() {
        return {
          currentFile: null,
          stackTrace: {
            isShow: false,
            line: null,
            file: null
          },
        };
    },
    async created() {

    },
    computed: {
        ...mapGetters({
            getLogFile: "logs/getLogFile",
            getLogLineDetail: "logs/getLogLineDetail"
        }),
    },
    methods: {
        ...mapActions({
            loadLogFile: 'logs/loadLogFile',
            loadLogLineDetail: "logs/loadLogLineDetail"
        }),
        async getStacktrace(isExists, file, line) {
          if (isExists) {
            let isShow = !(this.stackTrace.file === file && this.stackTrace.line === line);

            if (!isShow) {
              this.stackTrace.file = null;
              this.stackTrace.isShow = isShow;
              this.stackTrace.line = null;
              return;
            }

            this.stackTrace.file = file;

            this.stackTrace.isShow = isShow;
            this.stackTrace.line = line;

            if (!(this.getLogLineDetail.file === file && this.getLogLineDetail.line === line)) {
              await this.loadLogLineDetail({file, line})
            }
          }
        },
       selectFile(file) {
          this.currentFile = file
          this.loadLogFile(file)
       },
    },
};
</script>

<style lang='scss' scoped>
  .logs-file{
    height: 100%;
    max-height: 900px;
  }

  .logs-file-wrapper{
    max-width: 100%;
  }

  .warning {
    color: darkorange;
  }

  .info {
    color: deepskyblue;
  }

  .error {
    color: darkred;
  }

  .transparent {
    color: transparent;
  }

  .stacktrace-chevron {
    position: absolute;
  }

  .stacktrace-data {
    border-radius: 10px;
    background: #e3e3e3;
  }
</style>